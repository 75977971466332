<template>
<div class="wrapper-out">
  <div class="wrapper">
      <img class="wrapper_img" src="../../images/login.png"/>
      <div class="wrapper_input">
          <img class="form-list-icon" src="../../images/phone-icon.png"/>
          <input v-model="username" class="wrapper_content" type="text" placeholder="请输入用户名" />
      </div>
      <div class="wrapper_input">
          <img class="form-list-icon form-list-icon2" src="../../images/icon-password.png"/>
          <input v-model="password" class="wrapper_content" type="password" placeholder="请输入密码" />
      </div>
      <div class="wrapper_login_button" @click="onLogin">登 录</div>
      <div class="wrapper_login_button" @click="onWxLogin">微信授权登录</div>
      <div class="wrapper_login_link" @click="goRegister">立即注册</div>
      <Toast v-if="show" :message="toastMessage"/>
  </div>
</div>
</template>
<script>
// import { reactive } from 'vue'
import qs from 'qs'
import { parseUrlParam, setCookie, getCookie, removeCookie } from '../../units/tools.js'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { reactive, toRefs, onMounted, onUnmounted } from 'vue'
import { post, ajaxParam, config } from '../../units/request.js'
import { Toast } from 'vant'
export default {
  name: 'Login',
  components: {
    Toast
  },
  setup () {
    const data = reactive({ username: '', password: '', openId: '', nickName: '' })
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    onMounted(() => {
      const href = window.location.href
      if (href.includes('?code')) {
        const code = parseUrlParam(href).code
        if (code && !getCookie('isLogin')) {
          getWxInfo(code)
        }
      } else {
      }
    })
    onUnmounted(() => {
      const href = window.location.href
      if (href.includes('?code')) {
        const urlRight = href.split('#')[1]
        const urlLeft = href.split('?')[0]
        window.location.href = urlLeft + '#' + urlRight
      }
    })
    const onLogin = async () => {
      const { username, password } = data
      if (username.length <= 0) {
        Toast('请输入用户名')
        return
      }
      if (password.length <= 0) {
        Toast('请输入密码')
        return
      }
      const param = {
        username: data.username,
        password: data.password,
        vipType: 0
      }
      ajaxParam(param)
      const res = await post('v2/user/doLogin.json', qs.stringify(param))
      if (res.data.code === '200') {
        setCookie('isLogin', true)
        setCookie('username', res.data.entity.userName)
        setCookie('userId', res.data.entity.id)
        // localStorage.isLogin = true
        // localStorage.username = res.data.entity.userName
        // localStorage.userId = res.data.entity.id
        store.commit('changeUserInfo', res.data.entity)
        router.replace({ name: 'HomeNew' })
      }
      if (res.data.code === '602' || res.data.code === '601') {
        Toast(res.data.msg)
      }
    }
    const goRegister = () => {
      router.push({ name: 'Register' })
    }
    const onWxLogin = async () => {
      // localStorage.removeItem('isLogin')
      // localStorage.removeItem('userId')
      // localStorage.removeItem('username')
      // localStorage.removeItem('cartList')
      // localStorage.removeItem('originAddress')
      // localStorage.removeItem('openId')
      removeCookie('isLogin')
      removeCookie('userId')
      removeCookie('username')
      removeCookie('openId')
      removeCookie('nickName')
      localStorage.removeItem('cartList')
      localStorage.removeItem('originAddress')
      if (!getCookie('openId') && !getCookie('isLogin')) {
        let redirectUrl = window.location.href
        redirectUrl = encodeURIComponent(redirectUrl)
        const appid = config.appId
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      }
      console.log(route)
    }
    const getWxInfo = async (code) => {
      const param = {
        code: code,
        appId: config.appId,
        secret: config.appSecret
      }
      ajaxParam(param)
      const res = await post('wx/v1/login.json', qs.stringify(param))
      data.openId = res.data.entity.openId
      data.userId = res.data.entity.id
      data.nickName = res.data.entity.nickName
      // localStorage.isLogin = true
      // localStorage.openId = data.openId
      // localStorage.userId = data.userId
      // localStorage.nickName = data.nickName
      setCookie('isLogin', true)
      setCookie('openId', data.openId)
      setCookie('userId', data.userId)
      localStorage.nickName = data.nickName
      store.commit('changeUserInfo', res.data.entity)
      router.replace('/')
    }
    const { username, password } = toRefs(data)
    return {
      username, password, onLogin, goRegister, onWxLogin
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper-out{
  position: absolute;
  left: 0;
  right:0;
  top:0;
  bottom: 0;
  background:#fff
}
.wrapper{
    position: absolute;
    left:0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .wrapper_img{
        display: block;
        width: .85rem;
        height:.71rem;
        margin: 0 auto .4rem auto
    }
    .wrapper_input{
        height: 0.48rem;
        padding: 0 .16rem;
        box-sizing: border-box;
        margin: 0 .4rem .16rem .4rem;
        border-bottom: 1px solid rgba(0,0,0,.1);
        position: relative;
        .form-list-icon{
          width: 0.16rem;
          height: 0.22rem;
          position: absolute;
          left:0; top: 50%;
          margin-top: -0.11rem
        }
        .form-list-icon2{
          width:.18rem;
          height:.21rem
        }
    }
    .wrapper_content{
        position:absolute;
        left: 0.4rem;
        line-height: .48rem;
        border:none;
        outline: none;
        background:none;
        font-size: .16rem;
        color: #777;
        &::placeholder {
            color: #777;
        }
    }
    .wrapper_login_button{
        height: .48rem;
        margin:.42rem .4rem .26rem .4rem;
        background:#52da5f;
        box-shadow: 0 .04rem .08rem 0 rgba(0,145,255,0.32);
        border-radius: .25rem;
        color:#fff;
        font-size: .16rem;
        line-height: .48rem;
        text-align: center;
    }
    .wrapper_login_link{

        font-size: .14rem;
        text-align: center;
        color: #777;
    }
}
</style>
